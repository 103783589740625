import { useMsal } from '@azure/msal-react';
import {
  addOrUpdateMSAccount,
  addRooms,
  getMSAccount,
  getOfficeRooms,
  getRoomsByOfficeId,
} from 'apis';
import o365badge from 'assets/images/o365badge.png';
import Button, { ButtonVariants } from 'components/button/button';
import O365AccountsTable from 'components/o365-accounts-table/o365AccountsTable';
import O365RoomsModal from 'components/o365-rooms-modal/o365RoomsModal';
import O365Modal from 'components/office-365-modal/o365Modal';
import { redirectURI } from 'constants/index';
import { addOrUpdateMSAccountType, ModalViews } from 'enums';
import { toaster } from 'helpers';
import { loginRequest } from 'helpers/authConfig';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import { layoutActions, office365Actions } from 'store/actions';
import { iAddRoomsArgs, iState } from 'types';
import Card from '../card/card';
import styles from './integrations.module.scss';

interface iProps {
  className?: string;
}

function Integrations(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { companyData } = state.homePage || {};
  const {
    o365UserRooms,
    isMStokensFetched = false,
    responseAfterLogin = null,
    fetchedMSAccounts = [],
    msAccountAccessResponse,
  } = state.office365 || {};
  const { invokeGetMSAccounts } = state.layout || {};
  const { id = '' } = companyData || {};
  const { instance } = useMsal();
  // const [isLoading, setIsLoading] = useState(false);
  const [storedUserRooms, setStoredUserRooms] = useState<any>([]);
  const [storedCompanyId, setStoredCompanyId] = useState('');
  // const [isAddRoomLoading, setIsAddRoomLoading] = useState(false);
  // const { activeMsAccount } = state.office365;

  // const [acesTkn, setAcesTkn] = useState('');

  const fetchStoredRoomsByUserId = async (msAccountId): Promise<any> => {
    if (!companyData) return;
    const response = await getRoomsByOfficeId(
      {
        msAccountId,
      },
      () => {}
    );
    if (response) {
      // //  console.log(
      //   o365UserRooms?.map((room) => room.nickname),
      //   'sendo'
      // );
      // const updatedRooms = o365UserRooms?.map((room) => {
      //   // const existingRoom = response.find(
      //   //   (responseRoom) =>
      //   //     responseRoom.emailAddress.toLocaleLowerCase() ===
      //   //     room.emailAddress?.toLocaleLowerCase()
      //   // );
      //   // if (existingRoom)
      //   //   return {
      //   //     ...existingRoom,
      //   //     status: existingRoom.status,
      //   //     custom_name: existingRoom.custom_name,
      //   //   };
      //   return room;
      // });
      // //  console.log(updatedRooms, 'updatedRooms');
      // if (existingRoom) {
      //   return {
      //     ...room,
      //     status: 'true',
      //     custom_name: existingRoom?.custom_name,
      //   };
      // }
      // //  console.log(room, 'updatedRooms1');
      // return room;
      // });
      // if (updatedRooms) {
      // //  console.log(updatedRooms, 'updatedRooms');
      // if (o365UserRooms) {
      //   //  console.log(o365UserRooms, 'second time');
      //   dispatch(office365Actions.setO365UserRooms(o365UserRooms));
      // }
      // if (!state.layout.isShowO365RoomsModal) {
      //   // //  console.log(
      //   //   'being called',
      //   //   roomsResponse,
      //   //   response.account,
      //   //   state.layout.isShowO365RoomsModal
      //   // );
      setStoredUserRooms(response);
      //  console.log(response, 'responsefromstored accounts');

      dispatch(layoutActions.toggleModal(ModalViews.isShowO365RoomsModal));
      // }
      // }
    }
  };

  // useEffect(() => {
  //   dispatch(office365Actions.setO365UserRooms([]));
  // }, []);

  useEffect(() => {
    //  console.log(o365UserRooms, storedUserRooms, 'storedUserRooms');
    if (storedUserRooms.length > 0) {
      const updatedRooms = o365UserRooms?.map((room) => {
        const existingRoom = storedUserRooms.find(
          (responseRoom) =>
            responseRoom.emailAddress.toLocaleLowerCase() ===
            room.emailAddress?.toLocaleLowerCase()
        );
        if (existingRoom) {
          //  console.log(existingRoom, 'existingRoomaboveUpdatedRooms');
          return {
            ...existingRoom,
            status: existingRoom.status,
            custom_name: existingRoom.custom_name,
            displayName: existingRoom.name,
            id: existingRoom.id,
          };
        }
        return room;
      });
      dispatch(office365Actions.setO365UserRooms(updatedRooms));
      //  console.log(updatedRooms, 'updatedRooms');
    }
  }, [storedUserRooms]);

  const callAccessToken = async (existingCompanyId: any): Promise<any> => {
    dispatch(office365Actions.setO365UserRooms([]));
    const roomsResponse = await getOfficeRooms(
      { msId: existingCompanyId },
      () => {}
    );
    if (Array.isArray(roomsResponse)) {
      dispatch(
        office365Actions.setO365UserRooms(
          roomsResponse.map((room) => {
            return {
              ...room,
              status: false,
              custom_name: '',
              customNameError: false,
            };
          })
        )
      );
    }

    if (!state.layout.isShowO365RoomsModal) {
      dispatch(layoutActions.toggleModal(ModalViews.isShowO365RoomsModal));
    }
  };

  const callAccessTokenForExistingAccount = async (
    existingCompanyId: any
  ): Promise<any> => {
    dispatch(office365Actions.setO365UserRooms([]));
    const roomsResponse = await getOfficeRooms(
      { msId: existingCompanyId },
      () => {}
    );
    //  console.log(roomsResponse, 'roomsResponse');
    if (Array.isArray(roomsResponse)) {
      dispatch(
        office365Actions.setO365UserRooms(
          roomsResponse.map((room) => {
            return {
              ...room,
              status: false,
              custom_name: '',
              customNameError: false,
            };
          })
        )
      );
    }
    fetchStoredRoomsByUserId(existingCompanyId);
  };
  // useEffect(() => {
  //   //  console.log(accounts, 'accounts');
  // }, [accounts, fetchStoredRoomsByUserId, isAddRoomLoading]);

  // useEffect(() => {
  //   //  console.log(o365UserRooms, 'profileupdatedSuccess1');
  //   if (
  //     Array.isArray(o365UserRooms) &&
  //     o365UserRooms?.length > 0 &&
  //     storedCompanyId
  //   ) {
  //     //  console.log('profileupdatedSuccess');
  //     fetchStoredRoomsByUserId(storedCompanyId);
  //   }
  // }, [storedCompanyId]);
  // useEffect(() => {
  //   if (activeMsAccount?.account) callAccessToken(activeMsAccount?.account);
  // }, [activeMsAccount?.account]);

  // useEffect(() => {
  // callMsGraph(activeMsAccount?.accessToken).then((roomsResponse) => {
  //   //  console.log(roomsResponse, 'roomsResponse');
  //   dispatch(userActions.setO365UserRooms(roomsResponse.value));
  //   dispatch(layoutActions.toggleModal(ModalViews.isShowO365RoomsModal));
  // });
  // }, [activeMsAccount?.account]);

  // const requestResourcesData = (account: any): any => {
  //   // Silently acquires an access token which is then attached to a request for MS Graph data
  //   instance
  //     .acquireTokenSilent({
  //       ...loginRequest,
  //       account,
  //     })
  //     .then((response) => {
  //       //  console.log(response, 'responseacquireToken');
  //       ;
  //       // callMsGraphGroups(response.accessToken).then((responseGroups) => {
  //       //   //  console.log(responseGroups, 'responseGroups');
  //       // });
  //     });
  // };

  // const handleLogout = (logoutType): any => {
  //   if (logoutType === 'popup') {
  //     instance.logoutPopup({
  //       postLogoutRedirectUri: '/user-settings',
  //       mainWindowRedirectUri: '/user-settings',
  //     });
  //   }
  // };

  // const testAccessToken = useCallback(() => {
  //   callMsGraph(acesTkn).then((response) =>
  //     //  console.log(response, 'testingresponse')
  //   );
  // }, []);

  const fetchMSAccounts = async (): Promise<any> => {
    const response = await getMSAccount(
      {
        compannyId: id,
      },
      () => {}
    );
    if (response) {
      //  console.log(response, 'fetchMsAccounts');
      dispatch(office365Actions.setFetchedMSAccounts(response));
      dispatch(office365Actions.setIsMSaccountsFetched(true));
    }
  };

  useEffect(() => {
    fetchMSAccounts();
  }, []);

  useEffect(() => {
    if (invokeGetMSAccounts) {
      fetchMSAccounts();
      dispatch(layoutActions.invokeFetchMSAccounts(false));
    }
  }, [invokeGetMSAccounts]);

  const addCompanieshandler = async (
    loginResponse,
    existingCompanyId = ''
  ): Promise<any> => {
    if (!msAccountAccessResponse) return;
    if (!existingCompanyId) {
      const response = await addOrUpdateMSAccount(
        {
          accessToken: msAccountAccessResponse.access_token || '',
          companyId: id,
          email: loginResponse.account.username,
          mutationType: addOrUpdateMSAccountType.CREATE,
          name: loginResponse.account.name,
          msId: loginResponse.account.homeAccountId,
          refreshToken: msAccountAccessResponse.refresh_token || '',
          accountDetails: loginResponse.account,
        },
        () => {}
      );
      if (response) {
        //  console.log(response, 'responseafter adding new account');
        setStoredCompanyId(response.id);
        dispatch(layoutActions.invokeFetchMSAccounts(true));
        toaster.success('Profile Added Successfully');
        callAccessToken(response.id);
      }
    }
    if (existingCompanyId) {
      const response = await addOrUpdateMSAccount(
        {
          id: existingCompanyId,
          accessToken: msAccountAccessResponse.access_token || '',
          companyId: id,
          email: loginResponse.account.username,
          mutationType: addOrUpdateMSAccountType.UPDATE,
          name: loginResponse.account.name,
          msId: loginResponse.account.homeAccountId,
          refreshToken: msAccountAccessResponse.refresh_token || '',
          accountDetails: loginResponse.account,
        },
        () => {}
      );
      if (response) {
        //  console.log(response, 'responseafter updating existing account');
        setStoredCompanyId(existingCompanyId);
        toaster.success('Profile Updated Successfully');
        // dispatch(layoutActions.invokeFetchMSAccounts(true));
        callAccessTokenForExistingAccount(existingCompanyId);
      }
    }
  };

  const addRoomsData = async (): Promise<void> => {
    if (!storedCompanyId) return;
    const office365RoomsToAdd = o365UserRooms
      .filter((room) => room.status === true)
      .map((room): iAddRoomsArgs => {
        return {
          address: room.address || '',
          audioDeviceName: room.audioDeviceName || '',
          bookingType: room.bookingType || '',
          building: room.building || '',
          capacity: `${room.capacity}` || '',
          custom_name: room.custom_name || '',
          displayDeviceName: room.displayDeviceName || '',
          emailAddress: room.emailAddress || '',
          floorLabel: room.floorLabel || '',
          floorNumber: room.floorNumber || '',
          geoCoordinates: room.geoCoordinates || '',
          isWheelChairAccessible: room.isWheelChairAccessible || false,
          label: room.label || '',
          name: room.displayName || '',
          nickname: room.nickname || '',
          phone: room.phone || '',
          roomid: room.id || '',
          status: room.status || false,
          tags: '',
          videoDeviceName: room.videoDeviceName || '',
        };
      });
    const response = await addRooms(
      {
        msAccountId: storedCompanyId,
        companyId: id,
        roomdata: office365RoomsToAdd,
      },
      () => {}
    );
    if (response) {
      toaster.success('Rooms Added Successfully');
      dispatch(layoutActions.toggleModal(ModalViews.isShowO365RoomsModal));
    }
  };

  // useEffect(() => {
  //   if(responseAfterLogin){

  //   }
  // }, [responseAfterLogin])

  const handleLogin = (): any => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        //  console.log(response, 'responseafter Logging in');

        dispatch(office365Actions.setResponseAfterLogin(response));

        // window.location.replace(
        //   `https://login.microsoftonline.com/${response.tenantId}/oauth2/v2.0/authorize?client_id=2440f7d0-b789-4ed3-91d9-ba94861b65a8&response_type=code&redirect_uri=${redirectURI}office365-auth&response_mode=query&scope=offline_access%20user.read%20mail.read%20Place.Read.All%20Calendars.Read%20Calendars.ReadWrite%20OnlineMeetings.ReadWrite%20Calendars.Read.Shared%20Files.ReadWrite.All&code_challenge=47DEQpj8HBSa-_TImW-5JCeuQeRkm5NMpJWZG3hSuFU
        //    &code_challenge_method=plain`
        // );

        window.location.replace(
          `https://login.microsoftonline.com/${response.tenantId}/oauth2/v2.0/authorize?client_id=2440f7d0-b789-4ed3-91d9-ba94861b65a8&response_type=code&redirect_uri=${redirectURI}office365-auth&response_mode=query&scope=offline_access%20user.read%20mail.read%20Place.Read.All%20Calendars.Read%20Calendars.ReadWrite%20OnlineMeetings.ReadWrite%20Calendars.Read.Shared%20Files.ReadWrite.All&code_challenge=47DEQpj8HBSa-_TImW-5JCeuQeRkm5NMpJWZG3hSuFU
           &code_challenge_method=plain`
        );

        //  console.log(response, 'responsefromloggingIn');
      })
      .catch((e) => {
        console.log(e);
      });

    // dispatch(layoutActions.toggleModal());
  };

  useEffect(() => {
    //  console.log('existingCompanyList', isMStokensFetched);
    if (isMStokensFetched) {
      //  console.log('msaccountwithSachin', fetchedMSAccounts);
      if (!responseAfterLogin.account) return;
      // dispatch(
      //   office365Actions.setActiveMsAccount(responseAfterLogin.account)
      // );
      const data = fetchedMSAccounts || [];
      const existingCompany = data?.find(
        (account) => account.email === responseAfterLogin.account?.username
      );
      if (!existingCompany) {
        addCompanieshandler(responseAfterLogin);
      } else {
        addCompanieshandler(responseAfterLogin, existingCompany.id);
      }
      // //  console.log('existingCompany', isMStokensFetched);
      dispatch(office365Actions.setIsMStokensFetched(false));
    }
  }, [isMStokensFetched]);

  // useEffect(() => {
  //   if (isMStokensFetched) fetchMSAccounts();
  // }, [isMStokensFetched]);

  //  console.log('isMStokensFetched1', isMStokensFetched);

  return (
    <div className={`${styles.integrationsMainContent} ${className}`}>
      <Card className="mb7">
        <div className={styles.container}>
          <div className={styles.badgeContainer}>
            <img src={o365badge} height="24px" width="24px" alt="" />
            <span className={styles.badgeText}>Office 365</span>
          </div>

          <span className={styles.contentData}>
            Add Office 365 as a data source. Show meeting room and calendar
            information on your screens
          </span>
          {/* <Button
            className={styles.screenzBtn}
            variant={ButtonVariants.SmallStd}
            btnName="Sign Out"
            //   icon={whiteAddIcon}
            onClick={() => {
              handleLogout('popup');
            }}
          /> */}
          <Button
            className={styles.screenzBtn}
            variant={ButtonVariants.SmallStd}
            btnName="Add  connection"
            onClick={() => {
              dispatch(layoutActions.toggleModal(ModalViews.isShowO365Modal));
            }}
          />
        </div>
      </Card>
      <O365RoomsModal addRoomsData={addRoomsData} />
      <O365Modal handleLogin={handleLogin} />

      <O365AccountsTable
        setStoredCompanyId={setStoredCompanyId}
        callAccessTkn={callAccessTokenForExistingAccount}
        msAccounts={fetchedMSAccounts}
      />

      {/* <Button
        className={styles.screenzBtn}
        variant={ButtonVariants.SmallStd}
        btnName="Test Expiry"
        //   icon={whiteAddIcon}
        onClick={() => testAccessToken()}
      /> */}
      {/* <AuthenticatedTemplate>
        <div>authenticated</div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h5 className="card-title">
          Please sign-in to see your profile information.
        </h5>
      </UnauthenticatedTemplate> */}
    </div>
  );
}

Integrations.defaultProps = {
  className: '',
};

export default Integrations;
